<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="approvalModal"
        ref="approvalModal"
        size="lg"
        :title="'Approuver la requête ' + this.$props.modelRef"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          
          
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires *</label>
                <textarea
                  v-model="aprovalForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary" type="submit">
              Valider
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelUid: {
      type: Number,
    },
    modelRef: {
      type: String,
    },
  },
  data() {
    return {
      show: true,
      aprovalForm: {
        requestUid: "",
        comments: "",
      },
    };
  },
  methods: {
    submit() {
        this.aprovalForm.requestUid = this.$props.modelUid;
        var _this = this;
        this.$http
        .post("/ops/requests/takeawaymeals/approve", this.aprovalForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              window.location.reload();
              _this.$toast.success(res.data.original.msg);
              break;

            case 500:
              _this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          _this.$toast.warning(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>
